<template>
    <div>
        <Banner></Banner>
        <BoxesArea></BoxesArea>
        <Works></Works>
        <Feedback></Feedback>

        <!-- Start Ready To Talk Area -->
        <section class="ready-to-talk">
            <div class="container">
                <h3>Want to find out more?</h3>
                <p>Talk to us to see how we can help!</p>
                <a href="/contact" class="btn btn-primary">Contact Us</a>
            </div>
        </section>
        <!-- End Ready To Talk Area -->
    </div>
</template>

<script>
import Banner from "./it-startup/Banner";
import BoxesArea from "./it-startup/BoxesArea";
import Works from "./it-startup/Works";
import Feedback from "./it-startup/Feedback";
export default {
    name: "ITStartup",
    components: {
        Banner,
        BoxesArea,
        Works,
        Feedback,
    },
    metaInfo: {
        title: "Home",
        meta: [
            {
                name: "description",
                content:
                    "Nyx Intelligence is a solutions consultancy that uses technology to automate and accelerate your business. Contact us now.",
            },
        ],
    },
};
</script>
