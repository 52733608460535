<template>
    <!-- Start Fun Facts Area -->
    <section class="funfacts-area ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>We keep working until we get it done and get it right!</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3>
                            21+
                        </h3>
                        <p>Coffees</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3>
                            35+
                        </h3>
                        <p>Unsnoozed alarms</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3>
                            17+
                        </h3>
                        <p>Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3>
                            7
                        </h3>
                        <p>Consultants</p>
                    </div>
                </div>
            </div>

            <div class="contact-cta-box">
                <h3>Have any questions?</h3>
                <p>Lets have a chat</p>
                <a href="/contact" class="btn btn-primary">Contact Us</a>
            </div>

            <div class="map-bg">
                <img src="../../../assets/img/map.png" alt="map" />
            </div>
        </div>
    </section>
    <!-- End Fun Facts Area -->
</template>

<script>
export default {
    name: "Funfacts",
};
</script>
