<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Contact Us</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Contact Info Area -->
        <section class="contact-info-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <a href="mailto:info@nyxintelligence.com">
                                <div class="icon">
                                    <feather type="mail"></feather>
                                </div>
                                <h3>Email Here</h3>
                                <p>info@nyxintelligence.com</p>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <a href="https://goo.gl/maps/2JYqJPmCzSLrqwZbA" target="_blank">
                                <div class="icon">
                                    <feather type="map-pin"></feather>
                                </div>
                                <h3>Visit Here</h3>
                                <p>
                                    Click here for Google Maps
                                </p>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box">
                            <a href="https://www.linkedin.com/company/nyx-intelligence/">
                                <div class="icon">
                                    <feather type="linkedin"></feather>
                                </div>

                                <h3>Connect Here</h3>
                                <p>
                                    Connect on LinkedIn
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact Info Area -->

        <!-- Start Contact Area -->
        <section class="contact-area ptb-80 pt-0">
            <div class="container">
                <div class="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div class="bar"></div>
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>

                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <img src="../../../assets/img/1.svg" alt="image" />
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <form id="contactForm" @submit="submitForm" method="post">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            v-model="name"
                                            class="form-control"
                                            required
                                            data-error="Please enter your name"
                                            placeholder="Name"
                                        />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            v-model="email"
                                            class="form-control"
                                            required
                                            data-error="Please enter your email"
                                            placeholder="Email"
                                        />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            v-model="phone"
                                            data-error="Please enter your number"
                                            class="form-control"
                                            placeholder="Phone"
                                        />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="msg_subject"
                                            id="msg_subject"
                                            v-model="subject"
                                            class="form-control"
                                            required
                                            data-error="Please enter your subject"
                                            placeholder="Subject"
                                        />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            class="form-control"
                                            id="message"
                                            v-model="message"
                                            cols="30"
                                            rows="5"
                                            required
                                            data-error="Write your message"
                                            placeholder="Your Message"
                                        ></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn btn-primary">
                                        <span
                                            v-if="formSubmitIsLoading"
                                            class="spinner-border spinner-border-sm mr-3"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Send Message
                                    </button>
                                    <br />
                                    <br />
                                    <div v-if="formSubmittedSuccessfully" id="msgSubmit" class="h5">
                                        Your message has successfully been received. We will get in touch shortly!
                                    </div>
                                    <div v-if="formSubmittedFailure" id="msgSubmit" class="h5">
                                        There was an error submitting your form. Please try again or drop us an email
                                        instead.
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <!-- End Contact Area -->
    </div>
</template>

<script>
export default {
    name: "Contact",
    data() {
        return {
            name: null,
            email: null,
            phone: null,
            subject: null,
            message: null,
            formSubmitIsLoading: false,
            formSubmittedSuccessfully: false,
            formSubmittedFailure: false,
        };
    },
    methods: {
        submitForm: async function(e) {
            this.formSubmitIsLoading = true;
            e.preventDefault();
            let msg =
                `New contact request:\n` +
                `Name: ${this.name}\n` +
                `Email: ${this.email}\n` +
                `Phone number: ${this.phone}\n` +
                `Subject: ${this.subject}\n` +
                `Message: ${this.message}\n`;
            try {
                await this.$axios.get(
                    `https://api.telegram.org/bot1838120131:AAH78zv8O3uByMAkdTynZ3pmqf7RcbnUFCE/sendMessage?chat_id=29777902&text=${encodeURIComponent(
                        msg
                    )}`
                );
                await this.$axios.get(
                    `https://api.telegram.org/bot1838120131:AAH78zv8O3uByMAkdTynZ3pmqf7RcbnUFCE/sendMessage?chat_id=48330713&text=${encodeURIComponent(
                        msg
                    )}`
                );
            } catch (e) {
                this.formSubmittedFailure = true;
            } finally {
                this.formSubmitIsLoading = false;
                this.formSubmittedSuccessfully = true;
            }
        },
    },
    metaInfo: {
        title: "Contact Us",
        meta: [
            {
                name: "description",
                content: "Talk to Nyx Intelligence to automate and accelerate your business.",
            },
        ],
    },
};
</script>
