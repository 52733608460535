<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>School of Science and Technology, Singapore</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>SST</h3>

                            <p>
                                The School of Science and Technoloogy, Singapore is a leading public secondary school in
                                Singapore. The school is known for its strong technology focus and for being a
                                frontrunner in improving teaching pedagogy. Nyx Intelligence supported SST in its
                                outreach to prospective students by automating student/parent queries on the SST website
                                with a chatbot. The easy to use interface coupled with carefully curated content
                                supported the efficient sharing of information amongst prospective students and their
                                parents.
                            </p>
                            <p>
                                The web development expertise and natural language processing functions help
                                organisations in automating such tasks to reduce undue burden on staff. These seasonal
                                increases in queries were sustainably handled through the effective use of technology.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>School of Science and Technology, Singapore</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Education, Customer Service</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "SST",
    data: function() {
        return {};
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
