import VueRouter from "vue-router";
// Pages
import ITStartup from "./components/landing-pages/ITStartup";
import About from "./components/landing-pages/it-startup/About";
import Projects from "./components/landing-pages/it-startup/Projects";
import ProjectDetails from "./components/other-pages/projects/ProjectDetails";
import Faq from "./components/other-pages/faq/Faq";
import NotFound from "./components/other-pages/not-found/NotFound";
import ComingSoon from "./components/other-pages/coming-soon/ComingSoon";
import Contact from "./components/landing-pages/it-startup/Contact";

// Projects
import InsurReport from "./components/landing-pages/it-startup/project-pages/InsurReport";
import NUS from "./components/landing-pages/it-startup/project-pages/NUS";
import iSLS from "./components/landing-pages/it-startup/project-pages/Isls";
import SingaporePoly from "./components/landing-pages/it-startup/project-pages/SingaporePoly";
import Ouch from "./components/landing-pages/it-startup/project-pages/Ouch";
import PolicyXchange from "./components/landing-pages/it-startup/project-pages/PolicyXchange";
import SST from "./components/landing-pages/it-startup/project-pages/SST";
import CrowdPotato from "./components/landing-pages/it-startup/project-pages/CrowdPotato";
import Cosmomo from "./components/landing-pages/it-startup/project-pages/Cosmomo";

export const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        { path: "/", component: ITStartup },
        { path: "/about", component: About },
        { path: "/projects", component: Projects },
        { path: "/project-details", component: ProjectDetails },
        { path: "/faq", component: Faq },
        { path: "/coming-soon", component: ComingSoon },
        { path: "/not-found", component: NotFound },
        { path: "/contact", component: Contact },

        // Projects
        { path: "/projects/insurreport", component: InsurReport },
        { path: "/projects/nus", component: NUS },
        { path: "/projects/isls", component: iSLS },
        { path: "/projects/singaporepoly", component: SingaporePoly },
        { path: "/projects/ouch", component: Ouch },
        { path: "/projects/policyxchange", component: PolicyXchange },
        { path: "/projects/sst", component: SST },
        { path: "/projects/crowdpotato", component: CrowdPotato },
        { path: "/projects/cosmomo", component: Cosmomo },

        // Catch all
        { path: "*", component: NotFound },
    ],
});
