<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>CrowdPotato</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>FoodBuddy</h3>

                            <p>
                                CrowdPotato is an early stage startup that is looking to revolutionse deliveries through
                                crowdsourcing. Nyx Intelligence supported CrowdPotato through the development of a
                                proof-of concept titled FoodBuddy. FoodBuddy helped by managing food delivery requests
                                and the dissemination of such orders to drivers.
                            </p>
                            <p>
                                FoodBuddy was built on the Telegram messaging platform to allow the company to break
                                into the delivery market. We, at Nyx, love the energy of new ideas and are committed to
                                sustainably supporting early ventures with our technical expertise.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>CrowdPotato</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Food, MVP Development</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "CrowdPotato",
    data: function() {
        return {};
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
