<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img src="../../assets/img/logo.png" alt="logo" />
                            </a>
                        </div>
                        <p>
                            Nyx intelligence is a technology consultancy founded in 2018 to provide end-to-end solutions
                            for our clients. As a dedicated and trustworthy partner, our team is here to support you in
                            your every technology need.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="list">
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/projects">Projects</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-contact-info">
                            <li>
                                <feather type="map-pin"></feather>1003 Bukit Merah Central, #07-06, Singapore 159836
                            </li>
                            <li>
                                <feather type="mail"></feather> Email:
                                <a href="mailto:info@nyxintelligence.com">info@nyxintelligence.com</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>&copy;{{ new Date().getFullYear() }} Nyx Intelligence (Pte. Ltd.). All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>

        <img src="../../assets/img/map.png" class="map" alt="map" />
        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape" /></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape" /></div>
        <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><feather type="arrow-up"></feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from "vue-backtotop";
export default {
    name: "Footer",
    components: { BackToTop },
};
</script>
