<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Cosmomo</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>Cosmomo</h3>

                            <p>
                                Cosmomo is a seed stage startup in the cosmetics ecommerce space. Cosmomo was looking to
                                revolutionise the way we buy cosmetic products by providing a means to access
                                interesting and trendy cosmetic products from around the world. Nyx Intelligence
                                supported Cosmomo in the development of the Cosmomo Marketplace. The solution was a
                                multi-vendor marketplace that allowed Cosmomo to manage and support vendors by providing
                                them with efficient inventory management tools, shipping integrations and a sleek and
                                modern marketplace for customer to purchase products from them. Nyx Intelligence
                                provided the end-to-end technology solution for the company such that Cosmomo could
                                tackle the other business needs it faced.
                            </p>
                            <p>
                                The project incorporated both mobile and web development work. The platform hosted
                                various features including a raffling system that allowed for bidders to get their
                                chance at limited edition goods. At Nyx we are comfortable building complicated
                                technology solutions that are tightly intertwined with careful business strategy. We
                                look to support our partners in making the best technology decisions.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>Cosmomo Pte. Ltd</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Beauty, E-Commerce</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2019 - 2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "Cosmomo",
    data: function() {
        return {
            items: [
                {
                    src: require("../../../../assets/img/works-image/project-images/cosmomo-works.png"),
                },
            ],
            index: null,
        };
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
