<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', { 'is-sticky': isSticky }]">
        <div class="startp-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/logo.png" alt="logo" />
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">
                            <li class="nav-item">
                                <a href="/" class="nav-link active"> Home</a>
                            </li>

                            <li class="nav-item">
                                <a href="/about" class="nav-link">About</a>
                            </li>
                            <li class="nav-item">
                                <a href="/projects" class="nav-link">Projects</a>
                            </li>
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">Contact Us</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </nav>
            </div>
        </div>
    </header>
    <!-- End Navbar Area -->
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            isSticky: false,
        };
    },

    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },

    computed: {
        shoppingCart() {
            return this.$store.state.cart;
        },
    },
};
</script>
