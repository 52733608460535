<template>
    <!-- Start Works Area -->
    <section class="works-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Our Recent Works</h2>
                <div class="bar"></div>
            </div>
        </div>

        <div class="works-slides">
            <carousel
                :autoplay="true"
                :paginationEnabled="false"
                :loop="true"
                :autoplaySpeed="true"
                :autoplayTimeout="5000"
                :perPageCustom="[
                    [0, 1],
                    [768, 2],
                    [1200, 3],
                    [1500, 4],
                ]"
            >
                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/recent-works/nus-recent-works.png" alt="image" />
                        <div class="works-content">
                            <h3><a href="/projects/nus">National University of Singapore</a></h3>
                            <p>
                                Education
                            </p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/recent-works/sp-recent-works.png" alt="image" />
                        <div class="works-content">
                            <h3><a href="/projects/singaporepoly">Singapore Polytechnic</a></h3>
                            <p>
                                Education, Chatbot
                            </p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/recent-works/px-recent-works.png" alt="image" />
                        <div class="works-content">
                            <h3><a href="/projects/policyxchange">PolicyXchange</a></h3>
                            <p>
                                InsurTech
                            </p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img
                            src="../../../assets/img/works-image/recent-works/insurreport-recent-works.png"
                            alt="image"
                        />
                        <div class="works-content">
                            <h3><a href="/projects/insurreport">InsurReport</a></h3>
                            <p>
                                InsurTech
                            </p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/recent-works/cosmomo-recent-works.png" alt="image" />
                        <div class="works-content">
                            <h3><a href="/projects/cosmomo">Cosmomo</a></h3>
                            <p>
                                E-Commerce
                            </p>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>

        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
    </section>
    <!-- End Works Area -->
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
    name: "Works",
    components: { Carousel, Slide },
};
</script>
