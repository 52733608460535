<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Ouch</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>Ouch</h3>

                            <p>
                                Ouch Inc is a health-tech company looking to reimagine healthcare services in the
                                digital world. Nyx Intelligence supported Ouch in the implementation of a checkback
                                mechanism on the Ouch application after medical procedures. This mechanism was conceived
                                to close the gap between customers and healthcare providers in terms of post-procedure
                                support. The tool uses a chatbot to check back on symptoms that the patient faces to
                                determine the need for a follow up appointment at various time intervals from the date
                                of procedure performed. The tool helps provide comfort and instils confidence through
                                the constant engagement of the patient, especially during trying periods.
                            </p>
                            <p>
                                The solution was supported by a chatbot that was built as part of web service and API
                                solution provided by Nyx Intelligence.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>Ouch</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Healthcare, Customer Service</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2018 - Present</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "Ouch",
    data: function() {
        return {};
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
