<template>
    <!-- Start Feedback Area -->
    <section class="feedback-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Hear from our clients</h2>
                <div class="bar"></div>
            </div>

            <div class="feedback-slides">
                <div class="client-feedback">
                    <div>
                        <slick ref="slick" :options="slickOptions" :asNavFor="$refs.slick2">
                            <div class="item">
                                <div class="single-feedback">
                                    <h3>Soh Yeow Liang</h3>
                                    <span>Co-Founder, PolicyXchange</span>
                                    <p>
                                        We were looking for Singapore based IT development house and we took a leap of
                                        faith with Nyx Intelligence in 2018. Till today (3 years and counting), NYX
                                        Intelligence continues to be that trusted and reliable IT partner of ours.
                                    </p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="single-feedback">
                                    <h3>Audrey Cheong</h3>
                                    <span>Corporate Communications Executive, SST</span>
                                    <p>
                                        Our experience working with Nyx was a breeze right from the start. Nyx's
                                        user-friendly platform was easy to use. The team was easily contactable and
                                        could provide solutions quickly when any technical difficulties arose. Thank you
                                        Nyx for the initiative and it was a pleasure working with you.
                                    </p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="single-feedback">
                                    <h3>Sarah</h3>
                                    <span>Corporate Communications Executive, Singapore Polytechnic</span>
                                    <p>
                                        We chose to go with Nyx Intelligence as we could not find a customised solution
                                        for our in-house needs. The team in Nyx was patient, deliberate and professional
                                        in working through with us an application we were finally comfortable with.
                                    </p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="single-feedback">
                                    <h3>MD. Aslam</h3>
                                    <span>IT Director, NUS SynCTI</span>
                                    <p>
                                        Nyx Intelligence did a really good job. It’s really great how easy the web
                                        crawler project is to manage and update, giving perfect output. I'm glad we
                                        decided to work with Nyx and looking forward to working together on future
                                        projects.
                                    </p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="single-feedback">
                                    <h3>Anil</h3>
                                    <span>Director, Ouch Inc</span>
                                    <p>
                                        The team at Nyx Intelligence is young and energetic, always looking to solve
                                        problems in innovative ways. They are our choice for IT services such as chatbot
                                        development due to the skills and reliable support they provide. They worked
                                        well with our internal team to ensure success in delivery.
                                    </p>
                                </div>
                            </div>
                        </slick>
                    </div>
                    <button class="prev-arrow slick-arrow">
                        <feather type="arrow-left"></feather>
                    </button>

                    <button class="next-arrow slick-arrow">
                        <feather type="arrow-right"></feather>
                    </button>
                </div>
            </div>
        </div>

        <div class="shape1">
            <img src="../../../assets/img/shape1.png" alt="shape" />
        </div>
        <div class="shape2 rotateme">
            <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
        <div class="shape4">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape5">
            <img src="../../../assets/img/shape5.png" alt="shape" />
        </div>
        <div class="shape6 rotateme">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape7">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape8 rotateme">
            <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
    </section>
    <!-- End Feedback Area -->
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel';
import Slick from "vue-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
export default {
    name: "Feedback",
    components: { Slick },
    data() {
        return {
            slickOptions: {
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: "linear",
                fade: true,
                autoplay: false,
                draggable: true,
                prevArrow: ".client-feedback .prev-arrow",
                nextArrow: ".client-feedback .next-arrow",
            },
        };
    },

    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },
    },
};
</script>
