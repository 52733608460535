<template>
    <!-- Start Main Banner -->
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5">
                            <div class="hero-content">
                                <h1>
                                    Driving Transformation, Enabling Growth
                                </h1>
                                <h1></h1>
                                <p>
                                    We apply the endless potential of technology to automate and accelerate your
                                    business.
                                </p>

                                <a href="/contact" class="btn btn-primary">Contact us now</a>
                            </div>
                        </div>
                        <!-- Replace this with something else? -->
                        <div class="col-lg-6 offset-lg-1">
                            <div class="banner-image">
                                <img
                                    src="../../../assets/img/banner-image/man.png"
                                    class="wow fadeInDown"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="man"
                                />
                                <img
                                    src="../../../assets/img/banner-image/code.png"
                                    class="wow fadeInUp"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="code"
                                />
                                <img
                                    src="../../../assets/img/banner-image/carpet.png"
                                    class="wow fadeInLeft"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="carpet"
                                />
                                <img
                                    src="../../../assets/img/banner-image/bin.png"
                                    class="wow zoomIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="bin"
                                />
                                <img
                                    src="../../../assets/img/banner-image/book.png"
                                    class="wow bounceIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="book"
                                />
                                <img
                                    src="../../../assets/img/banner-image/dekstop.png"
                                    class="wow fadeInDown"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="dekstop"
                                />
                                <img
                                    src="../../../assets/img/banner-image/dot.png"
                                    class="wow zoomIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="dot"
                                />
                                <img
                                    src="../../../assets/img/banner-image/flower-top-big.png"
                                    class="wow fadeInUp"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="flower-top-big"
                                />
                                <img
                                    src="../../../assets/img/banner-image/flower-top.png"
                                    class="wow rotateIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="flower-top"
                                />
                                <img
                                    src="../../../assets/img/banner-image/keyboard.png"
                                    class="wow fadeInUp"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="keyboard"
                                />
                                <img
                                    src="../../../assets/img/banner-image/pen.png"
                                    class="wow zoomIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="pen"
                                />
                                <img
                                    src="../../../assets/img/banner-image/table.png"
                                    class="wow zoomIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="table"
                                />
                                <img
                                    src="../../../assets/img/banner-image/tea-cup.png"
                                    class="wow fadeInLeft"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="tea-cup"
                                />
                                <img
                                    src="../../../assets/img/banner-image/headphone.png"
                                    class="wow rollIn"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="headphone"
                                />

                                <img
                                    src="../../../assets/img/banner-image/main-pic.svg"
                                    class="wow fadeInUp"
                                    v-wow
                                    data-wow-delay="1s"
                                    alt="main-pic"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1">
            <img src="../../../assets/img/shape1.png" alt="shape" />
        </div>
        <div class="shape2 rotateme">
            <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
        <div class="shape3">
            <img src="../../../assets/img/shape3.svg" alt="shape" />
        </div>
        <div class="shape4">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape5">
            <img src="../../../assets/img/shape5.png" alt="shape" />
        </div>
        <div class="shape6 rotateme">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape7">
            <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape8 rotateme">
            <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
export default {
    name: "Banner",
};
</script>
