<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>International Singapore Lipid Symposium</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>International Singapore Lipid Symposium</h3>

                            <p>
                                The International Singapore Lipid Symposium is a biennial conference that invites
                                prominent researchers from all over the world for talks and workshops run by the
                                Singapore Lipidomics Incubator, SLING. Nyx Intelligence developed a mobile application
                                and admin panel for the iSLS team to manage the event effectively. The application
                                included features such as registration, entry pass validation, activity attendance
                                capturing, live Q&amp;A, polls, announcements, files dissemination and breakout sessions
                                management.
                            </p>
                            <p>
                                The mobile application was built for both Android and iOS operating systems.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>International Singapore Lipid Symposium</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Education, Events</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "isls",
    data: function() {
        return {
            items: [
                {
                    src: require("../../../../assets/img/works-image/project-images/isls-works.png"),
                },
            ],
            index: null,
        };
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
