<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>InsurReport</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>InsurReport</h3>
                            <p>
                                InsurReport is an InsurTech company in the car insurance space run by industry veterans.
                                Nyx Intelligence supported InsurReport in the development of its primary offering: a
                                mobile application for customers to manage their car insurance claims by guiding them
                                through data collection in any claim related event.
                            </p>
                            <p>The mobile application was built for both Android and iOS operating systems.</p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>Gao Dim (Pte. Ltd)</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>InsurTech</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2019 - Present</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "InsurReport",
    data: function() {
        return {
            items: [
                {
                    src: require("../../../../assets/img/works-image/project-images/insurreport-works.png"),
                },
            ],
            index: null,
        };
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
