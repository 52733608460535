<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <feather type="server"></feather>
                        </div>
                        <h3>Consultancy</h3>
                        <p>
                            Crafting the best technology strategies and building sustainable solutions
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon">
                            <feather type="code"></feather>
                        </div>
                        <h3>High Quality Code</h3>
                        <p>
                            Ensuring you receive high-quality, maintainable and scalable code.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon">
                            <feather type="users"></feather>
                        </div>
                        <h3>Knowledge Transfer</h3>
                        <p>
                            Transferring knowledge to your in-house team to ensure continuity.
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon">
                            <feather type="git-branch"></feather>
                        </div>
                        <h3>Consistent Support</h3>
                        <p>
                            Walking the digital transformation journey with you every step of the way.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: "BoxesArea",
};
</script>
