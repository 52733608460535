<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Singapore Polytechnic</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>SParky</h3>

                            <p>
                                Singapore Polytechnic is one of Singapore’s oldest and most reputable tertiary education
                                institutions. The institution receives numerous enquiries: from enquiries regarding its
                                plethora of diploma and postgraduate courses to information that prospective and current
                                students seek. Nyx Intelligence developed SParky, an AI Chatbot which automates the
                                answering of popular questions. SParky has many interesting features but one notable
                                feature is the ability to ask Sparky for directions when in SP. SP’s interesting network
                                of staircases, roads and corridors outwitted the formidable Google Maps and gave users
                                an effective way to find directions.
                            </p>
                            <p>
                                SParky provides users with detailed directions after being told the starting and ending
                                location of the user’s journey. These instructions are also coupled with a map to guide
                                the user. The solution is an AI Chatbot on the SP website supported by natural language
                                processing and AI classifiers for better query results.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>Singapore Polytechnic</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>Education, Chatbot</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2017 - Present</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "SingaporePoly",
    data: function() {
        return {
            items: [
                {
                    src: require("../../../../assets/img/works-image/project-images/sparky-works.png"),
                },
            ],
            index: null,
        };
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
