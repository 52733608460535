<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Our Past Works</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Works Area -->
        <section class="works-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/insurreport">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/insurreport-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a>InsurReport</a></h3>
                                    <p>
                                        InsurTech
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/nus">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/nus-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/nus">National University of Singapore</a></h3>
                                    <p>
                                        Education
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/isls">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/isls-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/isls">International Singapore Lipid Symposium</a></h3>
                                    <p>
                                        Events
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/singaporepoly">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/sp-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/singaporepoly">Singapore Polytechnic</a></h3>
                                    <p>
                                        Education, Chatbot
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/ouch">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/ouch-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/ouch">Ouch</a></h3>
                                    <p>
                                        MedTech
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/policyxchange">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/px-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/policyxchange">PolicyXchange</a></h3>
                                    <p>
                                        InsurTech
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/sst">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/sst-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/sst">School of Science and Technology, Singapore</a></h3>
                                    <p>
                                        Education, Chatbot
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/crowdpotato">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/crowdpotato-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/crowdpotato">CrowdPotato</a></h3>
                                    <p>
                                        Food, MVP Development
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <a href="/projects/cosmomo">
                            <div class="single-works">
                                <img
                                    src="../../../assets/img/works-image/logo-thumbnails/cosmomo-thumbnail.png"
                                    alt="image"
                                />
                                <div class="works-content">
                                    <h3><a href="/projects/cosmomo">Cosmomo</a></h3>
                                    <p>
                                        E-Commernce
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
        </section>
        <!-- End Works Area -->
    </div>
</template>

<script>
export default {
    name: "Projects",
    metaInfo: {
        title: "Projects",
        meta: [
            {
                name: "description",
                content:
                    "Comprehensive portfolio of work done by Nyx Intelligence. Talk to Nyx Intelligence to automate and accelerate your business.",
            },
        ],
    },
};
</script>
