<template>
    <!-- Start Team Area -->
    <section class="team-area ptb-80 bg-f9f6f6">
        <div class="container">
            <div class="section-title">
                <h2>Our Awesome Team</h2>
                <div class="bar"></div>
                <p>
                    Check us out and reach out if you’d like to chat!
                </p>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="single-team col-lg-3 col-md-6 col-sm-6">
                <div class="team-image">
                    <img src="../../../assets/img/team-image/azeem.jpg" alt="image" />
                </div>

                <div class="team-content">
                    <div class="team-info">
                        <h3>Azeem Vasanwala</h3>
                        <span>Co-Founder &amp; Technical Lead</span>
                    </div>

                    <ul>
                        <li>
                            <a href="https://linkedin.com/in/azeemvasanwala" target="_blank"
                                ><feather type="linkedin"></feather
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="single-team col-lg-3 col-md-6 col-sm-6 offset-lg-1">
                <div class="team-image">
                    <img src="../../../assets/img/team-image/ian.jpg" alt="image" />
                </div>

                <div class="team-content">
                    <div class="team-info">
                        <h3>Ian Soo</h3>
                        <span>Co-Founder &amp; Technical Lead</span>
                    </div>

                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/sooian/" target="_blank"
                                ><feather type="linkedin"></feather
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="single-team col-lg-3 col-md-6 col-sm-6 offset-lg-1">
                <div class="team-image">
                    <img src="../../../assets/img/team-image/vivek.jpg" alt="image" />
                </div>

                <div class="team-content">
                    <div class="team-info">
                        <h3>Vivek Tadikamalla</h3>
                        <span>Co-Founder &amp; Product and Operations Lead</span>
                    </div>

                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/vivek-tadikamalla/" target="_blank"
                                ><feather type="linkedin"></feather
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Team",
};
</script>
