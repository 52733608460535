<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>PolicyXchange</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Project Details Area -->
        <section class="project-details-area pt-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <div class="project-details-image">
                            <img :src="image.src" alt="work" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-desc">
                            <h3>MyInsurFolio</h3>

                            <p>
                                PolicyXchange(PX) is a thriving InsurTech startup that is reimagining the insurance
                                space by aiding insurance agents in the market. Nyx intelligence has helped develop PX’s
                                primary offering - MyInsurFolio. MyInsurFolio is a digital client management solution
                                for insurance agents to better manage and support their clients in their insurance
                                related matters. This allows agents to better organise information while providing their
                                customers with a platform to understand their insurance coverage better.
                            </p>
                            <p>
                                Nyx Intelligence has supported PX in various other projects. The web development
                                expertise provided by Nyx has borne fruits with an excellent web solution that insurance
                                agents and their customers are able to use. Enhancing user experience and creating great
                                products is what we at Nyx are obsessed with.
                            </p>

                            <div class="project-details-information">
                                <div class="single-info-box">
                                    <h4>Happy Client</h4>
                                    <p>PolicyXchange Pte. Ltd.</p>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <p>InsurTech</p>
                                </div>

                                <div class="single-info-box year-box">
                                    <h4>Year</h4>
                                    <p>2018 - Present</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Project Details Area -->
    </div>
</template>

<script>
export default {
    name: "PolicyXchange",

    data: function() {
        return {
            items: [
                {
                    src: require("../../../../assets/img/works-image/project-images/px-works.png"),
                },
            ],
            index: null,
        };
    },
    methods: {
        setIndex(index) {
            this.index = index;
        },
    },
};
</script>
