<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>About Us</h2>
                    </div>
                </div>
            </div>

            <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape" /></div>
            <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
            <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape" /></div>
            <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape" /></div>
            <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape" /></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape" /></div>
        </div>
        <!-- End Page Title -->

        <!-- Start About Area -->
        <section class="about-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="../../../assets/img/1.svg" alt="image" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <div class="section-title">
                                <h2>About Us</h2>
                                <div class="bar"></div>
                                <p>
                                    Nyx Intelligence is built on the fundamental understanding that technology brings
                                    meaningful benefits to our world. We harness the benefits of technological
                                    innovation to recreate experiences and reimagine the way we work.
                                </p>
                                <br />
                                <p>
                                    Built on the belief that technological innovation brings good, we explore
                                    opportunities in challenges and offer steadfast support to all our clients as their
                                    technology partner. Nyx Intelligence is your determined, dependable and dedicated
                                    technology partner.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-inner-area">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="about-text">
                                <h3>Our History</h3>
                                <p>
                                    Nyx Intelligence was established in 2018 on the backdrop of our founders winning
                                    hackathons and assembling a capable team interested in technological innovation.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="about-text">
                                <h3>Our Philosophy</h3>
                                <p>
                                    Nyx Intelligence believes that for the village to do well, all must prosper
                                    together. The success of our clients is our success and we live to support and
                                    deliver effectively for everyone to prosper together.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="about-text">
                                <h3>Who we are</h3>
                                <p>
                                    Nyx Intelligence is made up of tech enthusiasts. A good conversation on technology
                                    bides better than politics or entertainment, but happiness and laughter is part of
                                    the deal when working with us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Area -->

        <Team></Team>

        <!-- Start Ready To Talk Area -->
        <section class="ready-to-talk">
            <div class="container">
                <h3>Ready to talk?</h3>
                <p>Our team is here to answer your questions</p>
                <a href="/contact" class="btn btn-primary">Contact Us</a>
            </div>
        </section>
        <!-- End Ready To Talk Area -->
        <Funfacts></Funfacts>
    </div>
</template>

<script>
import Team from "./Team";
import Funfacts from "./Funfacts";
export default {
    name: "About",
    components: {
        Team,
        Funfacts,
    },
    metaInfo: {
        title: "About Us",
        meta: [
            {
                name: "description",
                content:
                    "Find out more about the talented and experienced team at Nyx Intelligence to help automate and acceleate your business.",
            },
        ],
    },
};
</script>
